<template>
    <div class="questionbank-box">
        <!-- 头部 -->
        <div class="head">
            <img src="../assets/img/questionBanner.jpg" alt="">
        </div>
        <div class="content">
            <div class="question-container">
                <!-- 面包屑和搜索 -->
                <div class="top">
                    <div class="crumbs">
                        <i>
                            <img src="../assets/img/loginIcon/index-icon.png" alt="">
                        </i>
                        <a href="/#/index">首页</a>
                        <p>></p>
                        <a href="javascript:;" class="clickActive">资讯中心</a>
                    </div>
                    <div class="searchInput">
                        <el-input
                        placeholder="请输入您感兴趣的课程"
                        clearable
                        v-model="inputValue"
                        @keyup.enter.native="toSearch"
                        >
                            <el-button class="searchBtn"
                                        size="mini"
                                        slot="append"
                                        icon="el-icon-search"
                                        @click="toSearch"
                            ></el-button>
                        </el-input>
                    </div>
                </div>
                <!-- 内容列表 -->
                <div class="listContent">
                    <ul>
                        <li v-for="(item, index) in questionList" :key="index">
                            <div class="img">
                                <img :src="item.logoUrl" alt="">
                            </div>
                            <div class="text">
                                <div class="title">
                                    {{item.name}}
                                </div>
                                <div class="detail">
                                    <p>试卷数量：<span>{{item.paperCount}}</span></p>
                                    <p>试题数量：<span>{{item.questionCount}}道</span></p>
                                    <p>参与：<span>{{item.viewCount}}人</span></p>
                                </div>
                                <div class="star" style="display: none;">
                                    <p>试题难度：</p>
                                    <star :size="48" :score="item.difficulty"></star>
                                </div>
                                <div class="author" style="display: none;">
                                    <p>编著：{{item.editor}}</p>
                                </div>
                            </div>
                            <div class="button" @click="jumpDetail(item.id)">
                                <a>
                                    进入题库
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import star from "../components/star/index"
export default {
    name: 'questionbank',
    data() {
        return {
            inputValue: '',
            questionList: []
        }
    },
    metaInfo: {
        title: '题库-网校', // set a title
        meta: [
            {             // set meta
                name: 'keyWords',
                content: '我是contact关键字'
            },
            {
                name: 'description',
                content: '我是contact描述'
            }
        ]
    },
    methods: {
        // 搜索获取关键字
        toSearch() {
            if (this.inputValue == '') return this.$message.error("搜索关键字为空!")
            // this.$store.commit("setSearch", this.inputValue);
            this.getSearch();
        },
        // 全部问题列表
        allQuestion() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/tiku/course/list.html'
            }).then((res)=>{
                this.questionList = res.data.result.list;
            })
        },
        // 调用搜索
        getSearch() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/tiku/course/list.html',
                data: {
                    searchKey: this.inputValue
                }
            }).then((res)=>{
                this.questionList = res.data.result.list;
            })
        },
        // 跳转
        jumpDetail(id) {
            this.$router.push({
                name:'questionBankDetail',
                params: {
                    "id":id
                }
            })
        }
    },
    created() {
        this.allQuestion()
    },
    components: {
        star,
    }
}
</script>
<style lang="scss">
    .questionbank-box {
        margin-top: 70px;
        width: 100%;
        min-width: 1200px;
        // 头部
        .head {
            width: 100%;
            min-width: 1200px;
            height: 150px;
        }
        // 内容
        .content {
            width: 100%;
            min-width: 1200px;
            padding-top: 60px;
            padding-bottom: 80px;
            box-sizing: border-box;
            background: #EEEEEE;
            .question-container {
                margin: 0 auto;
                width: 1200px;
                .top {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 40px;
                    .crumbs {
                        display: flex;
                        align-items: center;
                        i {
                            display: inline-block;
                            margin-right: 5px;
                            width: 14px;
                            height: 14px;
                        }
                        p {
                            margin-left: 5px;
                            margin-right: 5px;
                        }
                        a {
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            display: inline-block;
                            color: #666666;
                        }
                        .clickActive {
                            color: #FF5A74;
                        }
                    }
                    .searchInput {
                        width: 431px;
                        .el-input__inner {
                            width: 343px;
                            height: 48px;
                            border: 1px solid #FF5A74;
                            border-radius: 5px 0px 0px 5px;
                        }
                        .el-input-group__append {
                            padding: 0 41px;
                            background: #FF5A74;
                            border-radius: 0px 5px 5px 0px;
                            border: 1px solid #FF5A74;
                            .el-icon-search {
                                color: #ffffff;
                            }
                        }
                    }
                }
                .listContent {
                    ul {
                        li {
                            display: flex;
                            width: 1200px;
                            background: #FFFFFF;
                            padding: 20px;
                            box-sizing: border-box;
                            margin-bottom: 30px;
                            .img {
                                width: 370px;
                                height: 204px;
                                margin-right: 30px;
                            }
                            .text {
                                margin-top: 21px;
                                margin-right: 119px;
                                .title {
                                    width: 520px;
                                    margin-bottom: 20px;
                                    padding-bottom: 7px;
                                    box-sizing: border-box;
                                    font-size: 22px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #333333;
                                    border-bottom: 1px solid #EEEEEE;
                                }
                                .detail {
                                    display: flex;
                                    margin-bottom: 20px;
                                    p {
                                        margin-right: 50px;
                                        font-size: 16px;
                                        font-family: Microsoft YaHei;
                                        font-weight: 400;
                                        color: #999999;
                                        span {
                                            font-weight: bold;
                                            color: #333333;
                                        }
                                    }
                                }
                                .star {
                                    margin-bottom: 21px;
                                    display: flex;
                                    p {
                                        font-size: 16px;
                                        font-family: Microsoft YaHei;
                                        font-weight: 400;
                                        color: #999999;
                                    }
                                }
                                .author {
                                    p {
                                        font-size: 16px;
                                        font-family: Microsoft YaHei;
                                        font-weight: 400;
                                        color: #999999;
                                    }
                                }
                            }
                            .button {
                                margin-top: 78px;
                                width: 120px;
                                height: 48px;
                                line-height: 48px;
                                background: #FF5A74;
                                border-radius: 4px;
                                text-align: center;
                                cursor: pointer;
                                a {
                                    font-size: 16px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #FFFFFF;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>